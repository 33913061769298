ul.items {
  /* regular padding */
  padding: 1em;
}

ul.items li {
  /* a subtle vertical fade */
  background: -webkit-linear-gradient(top, #dff 0%, rgba(255, 255, 255, 0) 100%);
  /* remove bullet */
  list-style: none;
  padding: 1ex 1em;
}

div#header {
  /* random dog pic, doesn't work, returns json :( */
  /* background-image: url("https://dog.ceo/api/breeds/image/random"); */
  background-image: url("https://cataas.com/cat");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 50vh;
  width: 100%;
  color: white;
  position: relative;
}

body {
  background-color: #f0f0f0;
  margin: 0;
  padding: 0;
  max-width: 20cm;
  margin: 0 auto;
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*#header h1 {
  font-size: 2em;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* don't fill all horizontal space */
  display: inline-block;
  padding: 2ex;
  margin: 0;
  /* put in center */
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, 0);
}